import React from 'react'
import { Heading, Text, Flex } from '@hunnyswap/uikit'
import styled from 'styled-components'
import router from 'next/router'

import BrandSection from './BrandSection'
import { StyledHomeButton, StyledSectionContent, StyledWrapSection } from '../styles'
import PageSection from 'components/PageSection'
import { sendEvent } from 'utils/google-analytics/actionSendEvent'
import { GOOGLE_ANALYTIC_EVENTS } from 'utils/google-analytics/event'

const StyledHeroBackground = styled.img`
  width: 100%;
`

const StyledWrapImgHunnyGirl = styled.div`
  width: 50%;
  margin: auto;
  margin-top: -200px;
  margin-left: 18%;
  z-index: 2;
`
const StyledHeroBannerText = styled.div`
  width: 100%;
  max-width: 400px;
  z-index: 1;
`
const StyledHeading = styled(Heading)`
  font-family: 'Spin Cycle OT' !important;
  font-weight: normal !important;
  text-transform: uppercase !important;
  font-size: min(5.5vw, 62px);
  text-align: center;
  line-height: 1.3em;
`

const StyledText = styled(Text)`
  font-family: 'Open Sans' !important;
  text-align: center;
  width: 100%;
  max-width: 240px;
  margin: auto;
  line-height: 1.3em;
  font-size: min(34px, 3.3vw);
`

const StyledWrapButton = styled.div`
  display: flex;
  max-width: 330px;
  margin: 36px auto;
  margin-bottom: 45%;
  justify-content: center;
`

const IntroSectionMobile: React.FC = () => {
  return (
    <>
      <StyledWrapSection
        style={{ margin: `0 auto 0`, padding: 0, backgroundImage: "url('/images/home/baner-background-mobile.jpg')" }}
      >
        <PageSection
          innerProps={{ style: { width: '100%', padding: 0, position: 'relative', overflow: 'hidden' } }}
          index={2}
          hasCurvedDivider={false}
          highLight={false}
          className="heading-top"
        >
          <StyledSectionContent style={{ marginTop: 96 }}>
            <Flex justifyContent="center">
              <StyledHeroBannerText>
                <StyledHeading mb={'12px'} color="#fff">
                  The Most Innovative Decentralised Exchange with diverse utilities <br /> on Avalanche
                </StyledHeading>
                <StyledText fontSize="min(34px, 3.3vw)" color="#fff">
                  Avalanche users can share swapping profits with HunnySwap.
                  <br /> SWAP. EARN. PLAY.
                </StyledText>
                <StyledWrapButton>
                  <StyledHomeButton
                    onClick={() => {
                      sendEvent(GOOGLE_ANALYTIC_EVENTS.JOIN_DISCORD);
                      window.open('https://discord.gg/d4fxKZFxPv')
                    }}
                    style={{ marginRight: 16 }}
                    scale="sm"
                  >
                    Join Discord
                  </StyledHomeButton>
                  <StyledHomeButton
                    scale="sm"
                    style={{}}
                    onClick={() => {
                      sendEvent(GOOGLE_ANALYTIC_EVENTS.ROUTE_SWAP);
                      router.push('swap')
                    }}
                  >
                    Start Swapping
                  </StyledHomeButton>
                </StyledWrapButton>
              </StyledHeroBannerText>
            </Flex>
          </StyledSectionContent>
        </PageSection>
      </StyledWrapSection>

      <StyledWrapSection
        style={{
          margin: `-18% auto 0`,
          padding: 0,
          background: 'linear-gradient(180deg, #2B2E9B 0%, #38BDB5 100%)',
          paddingBottom: '25%',
        }}
      >
        <PageSection
          innerProps={{ style: { width: '100%', padding: 0, position: 'relative' } }}
          index={2}
          hasCurvedDivider={false}
          highLight={false}
          className="heading-top"
        >
          <StyledSectionContent style={{ marginTop: 96 }}>
            <StyledWrapImgHunnyGirl>
              <StyledHeroBackground
                style={{ width: 520 }}
                className="hunny-girl"
                src="/images/home/hunny-girl-mobile.png"
              />
            </StyledWrapImgHunnyGirl>
            <Flex justifyContent="center">
              <StyledHeroBannerText>
                <StyledHeading mb={'12px'} color="#fff">
                  The Hunny Ecosystem
                </StyledHeading>
              </StyledHeroBannerText>
            </Flex>
            <BrandSection />
          </StyledSectionContent>
        </PageSection>
      </StyledWrapSection>
    </>
  )
}

export default IntroSectionMobile
