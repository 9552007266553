import { useMatchBreakpoints } from '@hunnyswap/uikit'
import React from 'react'
import TokenNomicSectionDesktop from './TokenNomicSectionDesktop'
import TokenNomicSectionMobile from './TokenNomicSectionMobile'

const TokenNomicSection: React.FC = () => {
  const { isMobile } = useMatchBreakpoints()

  return isMobile ? <TokenNomicSectionMobile /> : <TokenNomicSectionDesktop />
}

export default TokenNomicSection
