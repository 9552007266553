import React from 'react'
import styled from 'styled-components'
import { Box, Text } from '@hunnyswap/uikit'

type SystemItem = { href: string; name: string; desc: string; img: string }

const imagePath = '/images/home/hunny-brand-logo/'

const ecosystemData: SystemItem[] = [
  {
    name: 'HUNNYSWAP DEX',
    desc: 'LIQUIDITY TO AVALANCHE PROJECTS',
    img: '/hunny-dex.png',
    href: '',
  },
  {
    name: 'HUNNYSWAP TRADE MINING',
    desc: 'REWARDING DEX USERS',
    img: '/hunny-trade-mining.png',
    href: '',
  },
  {
    name: 'CASINO',
    desc: '',
    img: '/hunny-play.png',
    href: 'https://hunnyplay.com/',
  },
  {
    name: 'POKER',
    desc: '',
    img: '/hunny-poker.png',
    href: 'https://hunnypoker.com/',
  },
  {
    name: 'HUNNYFINANCE FARM',
    desc: 'YIELD AGGREGATOR',
    img: '/hunny-farm.png',
    href: 'https://hunny.finance/',
  },
  {
    name: 'NFT MARKETPLACE',
    desc: 'REWARDING HUNNYPLAY USERS',
    img: '/hunny-nft.png',
    href: 'https://nft.hunny.finance/',
  },
  {
    name: 'HUNNYDAO',
    desc: 'DUAL BACKED TREASURY',
    img: '/hunny-dao.png',
    href: 'https://dao.hunny.finance/',
  },
]

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  width: 100%;
  margin-top: 128px;
  margin-bottom: 64px;
  flex-wrap: wrap;
  align-items: flex-start;

  @media (max-width: 900px) {
    margin-top: 64px;
    margin-bottom: 32px;
  }
`

const StyledItemContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  width: 25%;
  min-width: 25%;
  margin-bottom: 96px;

  cursor: pointer;

  @media (max-width: 900px) {
    margin-bottom: 48px;
  }

  @media (max-width: 750px) {
    width: 50%;
    min-width: 50%;
  }
`

const StyledImgContainer = styled.div`
  height: 130px;

  img {
    height: 100%;
    width: auto;
  }

  @media (max-width: 1100px) {
    height: 100px;
  }

  @media (max-width: 576px) {
    height: 70px;
  }
`

const StyledText = styled(Text)`
  font-size: 18px;
  @media (max-width: 576px) {
    font-size: 14px;
  }
`

const StyledDescText = styled(Text)`
  font-size: 16px;
  @media (max-width: 576px) {
    font-size: 12px;
  }
`

const BrandSection = () => {
  return (
    <StyledWrapper>
      {ecosystemData.map((item) => (
        <SystemItem key={item.name} {...item} />
      ))}
    </StyledWrapper>
  )
}

const SystemItem: React.FC<SystemItem> = ({ href, name, desc, img }) => {
  return (
    <StyledItemContainer
      onClick={() => {
        if (!href) return
        window.open(href)
      }}
    >
      <StyledImgContainer>
        <img src={`${imagePath}${img}`} />
      </StyledImgContainer>
      <StyledText maxWidth="80%" fontWeight="600" textAlign="center" mt="12px">
        {name}
      </StyledText>
      <StyledDescText maxWidth="80%" fontWeight="100" textAlign="center" fontFamily="Open Sans" color="#b2afb9">
        {desc}
      </StyledDescText>
    </StyledItemContainer>
  )
}

export default BrandSection
