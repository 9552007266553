import React from 'react'
import { Heading, Text, Flex } from '@hunnyswap/uikit'
import styled from 'styled-components'
import router from 'next/router'

import BrandSection from './BrandSection'
import { StyledHomeButton, StyledSectionContent, StyledWrapSection } from '../styles'
import PageSection from 'components/PageSection'
import { sendEvent } from 'utils/google-analytics/actionSendEvent'
import { GOOGLE_ANALYTIC_EVENTS } from 'utils/google-analytics/event'

const StyledHeroBackground = styled.img`
  min-width: 200%;
  margin-left: -50%;
  margin-top: 15%;
`

const StyledWrapImgHunnyGirl = styled.div`
  width: 25%;
`
const StyledHeroBannerText = styled.div`
  left: 15%;
  top: 150px;

  width: 55%;
  z-index: 1;
`
const StyledHeading = styled(Heading)`
  font-family: 'Spin Cycle OT' !important;
  font-weight: normal !important;
  text-transform: uppercase !important;
  font-size: min(4.3vw, 62px);
`

const StyledText = styled(Text)`
  font-family: 'Open Sans' !important;
`

const StyledWrapButton = styled.div`
  display: flex;
  width: 330px;
`

const IntroSectionDesktop: React.FC = () => {
  return (
    <>
      <StyledWrapSection
        style={{ margin: `0 auto 0`, padding: 0, backgroundImage: "url('/images/home/baner-background.jpg')" }}
      >
        <PageSection
          innerProps={{ style: { width: '100%', padding: 0, position: 'relative', overflow: 'hidden' } }}
          index={2}
          hasCurvedDivider={false}
          highLight={false}
          className="heading-top"
        >
          <StyledSectionContent style={{ marginTop: 96 }}>
            <Flex justifyContent="center">
              <StyledHeroBannerText>
                <StyledHeading className="heading" mb={'24px'} color="#fff">
                  The Most Innovative Decentralised Exchange with diverse utilities <br /> on Avalanche
                </StyledHeading>
                <StyledText
                  fontSize="min(34px, 2.5vw)"
                  mb="24px"
                  color="#fff"
                  style={{ fontWeight: 500, maxWidth: '85%' }}
                >
                  Avalanche users can share swapping profits with HunnySwap.
                  <br /> SWAP. EARN. PLAY.
                </StyledText>
                <StyledWrapButton>
                  <StyledHomeButton
                    onClick={() => {
                      sendEvent(GOOGLE_ANALYTIC_EVENTS.JOIN_DISCORD);
                      window.open('https://discord.gg/d4fxKZFxPv')
                    }}
                    style={{ marginRight: 16 }}
                    scale="sm"
                  >
                    Join Discord
                  </StyledHomeButton>
                  <StyledHomeButton
                    scale="sm"
                    style={{}}
                    onClick={() => {
                      sendEvent(GOOGLE_ANALYTIC_EVENTS.ROUTE_SWAP);

                      router.push('swap')
                    }}
                  >
                    Start Swapping
                  </StyledHomeButton>
                </StyledWrapButton>
              </StyledHeroBannerText>

              <StyledWrapImgHunnyGirl>
                <div data-aos="fade-left" data-aos-delay="1500" style={{ height: '100%' }}>
                  <StyledHeroBackground
                    style={{ width: 520 }}
                    className="hunny-girl"
                    src="/images/home/hunny-girl.png"
                  />
                </div>
              </StyledWrapImgHunnyGirl>
            </Flex>
            <Flex flexDirection="column" justifyContent="center" alignItems="center" mt="64px">
              <StyledHeading
                className="heading"
                mb={'24px'}
                color="#fff"
                style={{
                  fontSize: 'min(3.4vw, 42px)',
                }}
              >
                The Hunny Ecosystem
              </StyledHeading>
              <BrandSection />
            </Flex>
          </StyledSectionContent>
        </PageSection>
      </StyledWrapSection>
    </>
  )
}

export default IntroSectionDesktop
