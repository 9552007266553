import React from 'react'
import styled from 'styled-components'
import CakeDataRow, { DataRowProps } from './CakeDataRow'
import { StyledWrapSection, StyledSectionContent } from '../styles'

const StyledSnowLine = styled.img`
  position: absolute;
  z-index: 1;
  bottom: 50%;
  width: 100%;
  transform: translate(-0%, 50%);

  max-height: 131px;
`

const DataSectionDesktop: React.FC<DataRowProps> = ({ data }) => {
  return (
    <StyledWrapSection style={{}}>
      <StyledSnowLine src="/images/home/snow-line.png" />
      <StyledSectionContent
        style={{ position: 'absolute', left: ' 50%', top: 0, transform: ' translate(-50%, -104%)', zIndex: 1 }}
      >
        <CakeDataRow data={data} />
      </StyledSectionContent>
    </StyledWrapSection>
  )
}

export default DataSectionDesktop
