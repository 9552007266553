import React from 'react'
import HunnyRace from '../RaceSection/HunnyRace'

import { StyledSectionContent, StyledWrapSection } from '../styles'
import ProjectInfoSection from './ProjectInfoSection'
import StakeToEarn from './StakeToEarn'

const IntroSectionDesktop: React.FC = () => {
  return (
    <>
      <StyledWrapSection
        style={{
          margin: `0 auto 0`,
          padding: 0,
          backgroundImage: "url('/images/home/baner-background-2-mobile.jpg')",
          backgroundPosition: 'bottom',
        }}
      >
        <StyledSectionContent>
          <ProjectInfoSection />
        </StyledSectionContent>
      </StyledWrapSection>

      <StyledWrapSection
        style={{
          margin: `0 auto 0`,
          padding: 0,
          background:
            'linear-gradient(rgb(88, 97, 196) 0%, rgb(56, 189, 181) 23.92%, rgb(128, 5, 218) 78.91%, rgb(0, 32, 53) 113.71%)',
        }}
      >
        <StyledSectionContent>
          <StakeToEarn />
        </StyledSectionContent>
      </StyledWrapSection>
    </>
  )
}

export default IntroSectionDesktop
