import { Flex, Heading, Skeleton, Text, useMatchBreakpoints } from '@hunnyswap/uikit'
import BigNumber from 'bignumber.js'
import Balance from 'components/Balance'
import cakeAbi from 'config/abi/cake.json'
import LockingChefABI from 'config/abi/lockingChef.json'
import tokens from 'config/constants/tokens'
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'
import { useSlowRefreshEffect } from 'hooks/useRefreshEffect'
import React, { useEffect, useState } from 'react'
import { getLockingChefAddress } from 'utils/addressHelpers'
import { formatBigNumber, formatLocalisedCompactNumber } from 'utils/formatBalance'
import { multicallv2 } from 'utils/multicall'
import CakeDataRowDesktop from './CakeDataRowDesktop'
import CakeDataRowMobile from './CakeDataRowMobile'

export type DataRowProps = {
  data: {
    liquidity: number
  }
}

const CakeDataRow: React.FC<DataRowProps> = ({ data }) => {
  const [cakeSupply, setCakeSupply] = useState(0)
  const cakePriceBusd = useCakeBusdPrice()
  const mcap = new BigNumber(cakePriceBusd?.toFixed(18)).multipliedBy(cakeSupply)
  const mcapString = formatLocalisedCompactNumber(Number(mcap.toFixed(3)))
  const tvlString = formatLocalisedCompactNumber(data.liquidity)

  useSlowRefreshEffect(() => {
    const fetchTokenData = async () => {
      const totalSupplyCall = { address: tokens.cake.address, name: 'totalSupply' }
      const vestingTokenCalls = [
        {
          address: tokens.cake.address,
          name: 'balanceOf',
          params: ['0x29F1ef13CCFAbC94ACE3E01FD1952af33c0907d2'],
        },
        {
          address: tokens.cake.address,
          name: 'balanceOf',
          params: ['0x5eFefBaB19b6d60075FE467f6abe427001563B34'],
        },
        {
          address: tokens.cake.address,
          name: 'balanceOf',
          params: ['0x30Ec48B5244f83b72e7511F4F7599109D9dEC46B'],
        },
        {
          address: tokens.cake.address,
          name: 'balanceOf',
          params: ['0xBeb54c81Cd628a1951Ce0b9487E2e225C0Ce4f22'],
        },
        {
          address: tokens.cake.address,
          name: 'balanceOf',
          params: ['0x84A301C93C528079A8c149cC89C9eAA0630f445D'],
        },
      ]

      const calls = [
        {
          name: 'totalReward',
          address: getLockingChefAddress(),
          params: [],
        },
        {
          name: 'totalDebts',
          address: getLockingChefAddress(),
          params: [],
        },
      ]

      const tokenDataResultRaw = await multicallv2(cakeAbi, [totalSupplyCall, ...vestingTokenCalls], {
        requireSuccess: false,
      })

      const lockResults = await multicallv2(LockingChefABI, calls, {})

      const [totalReward, totalDebts] = lockResults.flat()

      const [totalSupply, vesting1, vesting2, vesting3, vesting4, tradeMining] = tokenDataResultRaw.flat()

      setCakeSupply(
        totalSupply
          ? +formatBigNumber(
            totalSupply
              .sub(totalReward)
              .sub(totalDebts)
              .sub(tradeMining)
              .sub(vesting1)
              .sub(vesting2)
              .sub(vesting3)
              .sub(vesting4),
          )
          : 0,
      )
    }

    fetchTokenData()
  }, [])

  const { isMobile } = useMatchBreakpoints()

  return isMobile ? (
    <CakeDataRowMobile tvlString={tvlString} mcapString={mcapString} />
  ) : (
    <CakeDataRowDesktop tvlString={tvlString} mcapString={mcapString} />
  )
}

export default CakeDataRow
