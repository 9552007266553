import React from 'react'
import styled from 'styled-components'
import CakeDataRow, { DataRowProps } from './CakeDataRow'
import { StyledWrapSection, StyledSectionContent } from '../styles'

const StyledSnowLine = styled.img`
  width: 100%;
`

const StyledStatueImg = styled.img`
  width: 50%;
  max-width: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
  bottom: 105%;
`

const DataSectionDesktop: React.FC<DataRowProps> = ({ data }) => {
  return (
    <StyledWrapSection style={{}}>
      <StyledSnowLine src="/images/home/ice.jpg" />
      <StyledStatueImg src="/images/home/statue.png" />
      <StyledSectionContent
        style={{ position: 'absolute', left: ' 50%', top: 0, transform: ' translate(-50%, -60%)', zIndex: 1 }}
      >
        <CakeDataRow data={data} />
      </StyledSectionContent>
    </StyledWrapSection>
  )
}

export default DataSectionDesktop
