import React from 'react'
import styled from 'styled-components'
import { sendEvent } from 'utils/google-analytics/actionSendEvent'
import { GOOGLE_ANALYTIC_EVENTS } from 'utils/google-analytics/event'
import { StyledHomeButton, StyledTwinkle } from '../styles'

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  top: 0px;

  width: 100%;
  margin: 0px auto;

  @media (max-width: 1400px) {
    max-width: 100%;
  }
`

const StyledImgBg = styled.img`
  width: 90%;

  @media (min-width: 1400px) {
    width: 90%;
  }
  @media (max-width: 767px) {
    margin: 24px 0;
    margin-top: -20px;
    width: 100%;
  }
`

const StyledTitle = styled.div`
  font-family: 'Spin Cycle OT';
  text-transform: uppercase;

  font-size: min(3.4vw, 42px);
  color: #fff;
  text-align: center;
  line-height: 32px;

  @media (max-width: 576px) {
    font-size: min(4.3vw, 62px);
    width: 50%;
    margin: auto;
  }
`

const StyledWrapSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

const imagePath = '/images/home/stake/'

const StakeToEarn = () => {
  return (
    <StyledWrapper>
      <StyledWrapSection>
        <StyledTitle style={{ marginTop: 48, marginBottom: 12 }}>STAKE TO START EARNING XOXO </StyledTitle>
        <div
          data-aos="zoom-in-up"
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <StyledImgBg style={{ zIndex: 2 }} src={`${imagePath}bg-bridge-2.png`} className="bg-2" />
        </div>
        <StyledTitle>BRIDGE-ABLE TOKENs TO Avalanche </StyledTitle>
        <StyledHomeButton
          style={{ marginTop: 16 }}
          onClick={() => {
            sendEvent(GOOGLE_ANALYTIC_EVENTS.HOW_TO_BRIDGE);
            window.open('https://docs.hunnyswap.com/getting-started/bridging-to-avalanche')
          }}
        >
          How To Bridge
        </StyledHomeButton>
      </StyledWrapSection>
      <StyledWrapSection style={{ marginBottom: 0 }} />
    </StyledWrapper>
  )
}

export default StakeToEarn
