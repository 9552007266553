import { Button } from '@hunnyswap/uikit'
import styled from 'styled-components'

export const StyledHomeButton = styled(Button)`
  background: linear-gradient(232.87deg, #00d0da 4.7%, #40d8ff 27.51%, #eb00f3 93.08%);
  box-shadow: 0px 10px 0px rgba(0, 114, 255, 0.39);
  color: white;
  border: 2px solid #fff;
  border-radius: 100px;
  font-family: 'Open Sans';
  height: 40px;
  padding: 8px 22px;
  box-sizing: border-box;
  @media (max-width: 1300px) {
    &:hover {
      filter: brightness(0.9);
      transition: all 0.1s ease-in-out;
    }
  }

  &::selection {
    background: transparent;
  }
  &::-moz-selection {
    background: transparent;
  }
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @media (max-width: 500px) {
    font-size: 12px;
    box-shadow: unset;
    padding: 9px 18px !important;
    height: unset !important;
  }
`

export const StyledTwinkle = styled.img<{ time: number, w: string }>`
animation: twinkle  ${({ time }) => time}s linear infinite;
width: ${({ w }) => w};

height: auto !important;
position: absolute;
z-index: 2;

&.center{
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
}

&.center-top{
  top: 0px;
  left: 50%;
  transform: translate(-50%, 0%);
}


&.bottom-right{
  bottom: 0px;
  right: 0%;
}
 
&.center-left{
  left: 0px;
  bottom: 50%;
  transform: translate(0%, 50%);
}

&.center-right{
  right: 0px;
  bottom: 50%;
  transform: translate(0%, 50%);
}
 

@media (max-width: 760px){
  &.only-desktop{
    display: none;
  }
}

@media (max-width: 1200px){
  &.only-large-desktop{
    display: none;
  }
}

@keyframes twinkle {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}
`


export const StyledWrapSection = styled.div`
  margin: 0px auto;
  max-width: 2560px;
  width: 100%;
  position: relative;

  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
`

export const StyledSectionContent = styled.div`
margin: auto;
width: 100%;
height: 100%;
max-width: 1368px;
padding: 64px;

@media (max-width: 800px) {
  padding: 64px 16px;
}

@media (max-width: 576px) {
  padding: 16px;
}
`