import React from 'react'

import { StyledSectionContent, StyledWrapSection } from '../styles'
import ProjectInfoSection from './ProjectInfoSection'
import StakeToEarn from './StakeToEarn'

const IntroSectionDesktop: React.FC = () => {
  return (
    <>
      <StyledWrapSection
        style={{
          margin: `0 auto 0`,
          padding: 0,
          backgroundImage: "url('/images/home/baner-background-2.jpg')",
          backgroundPosition: 'bottom',
        }}
      >
        <StyledSectionContent>
          <ProjectInfoSection />
          <StakeToEarn />
        </StyledSectionContent>
      </StyledWrapSection>
    </>
  )
}

export default IntroSectionDesktop
