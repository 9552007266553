import React from 'react'
import styled from 'styled-components'
import { sendEvent } from 'utils/google-analytics/actionSendEvent'
import { GOOGLE_ANALYTIC_EVENTS } from 'utils/google-analytics/event'
import { StyledHomeButton } from '../styles'

const StyledInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 128px;
  padding-bottom: 72px;
  width: 75%;
  margin: auto;

  @media (max-width: 900px) {
    padding-top: 64px;
  }

  @media (max-width: 576px) {
    padding-top: 32px;
    padding-bottom: 0px;
    width: 90%;
  }
`

const StyledBackgroundContainer = styled.div``

const StyledBackgroundImg = styled.img`
  min-width: 170%;
  margin-left: -20%;
  z-index: 2;
  display: flex;

  @media (max-width: 1000px) {
    min-width: 150%;
    margin-left: 0px;
  }

  @media (max-width: 900px) {
    min-width: 100%;
  }
`

const StyledInfoText = styled.div`
  min-width: 40%;
  max-width: 500px;
  z-index: 10;

  @media (max-width: 576px) {
    min-width: 55%;
  }
`

const StyledContent = styled.p`
  font-family: 'Open Sans';
  color: #fff;
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 26px;
  text-align: left;

  margin-top: 24px;

  display: block;

  @media (max-width: 900px) {
    display: none;
  }

  &.tablet {
    display: none;
    text-align: center;

    @media (max-width: 900px) {
      display: block;
    }
  }

  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 24px;
  }
`

const BulletList = styled.ul`
  list-style-type: none;
  color: #fff;
  font-style: normal;
  font-weight: 100;

  li {
    font-family: 'Open Sans';

    margin: 0;
    padding: 0;
    font-size: 16px;
    display: flex;
    align-items: baseline;

    margin-bottom: 4px;
    line-height: 1.4em;

    .circle {
      margin-right: 8px;
      min-width: 12px;
      width: 12px;
      height: 12px;
      background-color: #38ffe7;
      border-radius: 50%;
      display: inline-block;
    }

    @media (max-width: 900px) {
      margin-bottom: 16px;
    }

    @media (max-width: 576px) {
      font-size: 12px;
    }
  }

  li::marker {
    font-size: 12px;
  }

  @media (max-width: 576px) {
    margin-top: 16px;
  }
`

const StyledTitle = styled.div`
  color: #fff;
  font-size: min(3.4vw, 42px);
  font-family: 'Spin Cycle OT';
  text-transform: uppercase;
  text-align: left;

  font-weight: 500;
  line-height: 32px;

  display: block;
  @media (max-width: 900px) {
    display: none;
  }

  &.tablet {
    display: none;
    text-align: center;

    @media (max-width: 900px) {
      display: block;
    }
  }

  @media (max-width: 576px) {
    font-size: min(4.3vw, 62px);
    width: 50%;
    margin: auto;
  }
`

const ReStyledButton = styled(StyledHomeButton)`
  margin-top: 32px;

  @media (max-width: 767px) {
    margin-top: 10px;
  }

  display: flex;

  &.mobile {
    display: none;
  }

  @media (max-width: 576px) {
    display: none;
    &.mobile {
      display: flex;
      max-width: 200px;
      margin: auto;
    }
  }
`

const imagePath = '/images/home/info/'

const ProjectInfoSection = () => {
  return (
    <>
      <StyledWrapper style={{ flexDirection: 'column' }}>
        <StyledTitle className="tablet">Introduction to XOXO Tokenomics</StyledTitle>
        <StyledContent className="tablet">
          Trade, Swap, Stake, Farm, Earn, Play, Win, Vote, and Have Fun with all Hunnies on the most engaging and fun
          destination in this magical DeFi world.
        </StyledContent>
        <StyledInnerWrapper>
          <StyledInfoText>
            <StyledTitle>Introduction to XOXO Tokenomics</StyledTitle>
            <StyledContent>
              Trade, Swap, Stake, Farm, Earn, Play, Win, Vote, and Have Fun with all Hunnies on the most engaging and
              fun destination in this magical DeFi world.
            </StyledContent>
            <BulletList>
              <li>
                <span className="circle" />
                Bridge
              </li>
              <li>
                <span className="circle" />
                <span>First Avalanche DeFi + Casino + NFT</span>
              </li>
              <li>
                <span className="circle" />
                Audited by Certik
              </li>
              <li>
                <span className="circle" />
                Leverage your fund in One Stop DeFi Protocol
              </li>
            </BulletList>

            <ReStyledButton
              onClick={() => {
                sendEvent(GOOGLE_ANALYTIC_EVENTS.GO_TO_WHITEPAPER);
                window.open('https://docs.hunnyswap.com/', '_blank')
              }}
            >
              Go To WhitePaper
            </ReStyledButton>
          </StyledInfoText>
          <StyledBackgroundContainer style={{ zIndex: 1 }}>
            <div data-aos="fade-right">
              <StyledBackgroundImg src={`${imagePath}background.png`} />
            </div>
          </StyledBackgroundContainer>
        </StyledInnerWrapper>
        <ReStyledButton
          className="mobile"
          onClick={() => {
            sendEvent(GOOGLE_ANALYTIC_EVENTS.GO_TO_WHITEPAPER);
            window.open('https://docs.hunnyswap.com/', '_blank')
          }}
        >
          Go To WhitePaper
        </ReStyledButton>
      </StyledWrapper>
    </>
  )
}

export default ProjectInfoSection
