import { useMatchBreakpoints } from '@hunnyswap/uikit'
import React, { useEffect, useState } from 'react'
import { getLiquidity } from 'views/Home/v1/helpers'
import DataSectionDesktop from './DataSectionDesktop'
import DataSectionMobile from './DataSectionMobile';


const DataSection: React.FC = () => {
  const { isMobile } = useMatchBreakpoints()
  const [dataLiq, setDataLiq] = useState<{ liquidity: number }>({ liquidity: 0 });

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getLiquidity();
        const [liquidity, volume] = data
          ?.filter((d) => d.liquidityUSD !== "0")
          ?.reduce(
            (previousValue, currentValue) => {
              previousValue[0].unshift({
                date: currentValue.date,
                value: parseFloat(currentValue.liquidityUSD),
              });
              previousValue[1].unshift({
                date: currentValue.date,
                value: parseFloat(currentValue.volumeUSD),
              });
              return previousValue;
            },
            [[], []]
          ) as [any, any];

        setDataLiq({
          liquidity: liquidity[liquidity.length - 1]?.value || "0",
        })
      } catch (err) {
        console.error(err);
      }
    }
    fetch();
  }, [])
  return isMobile ? <DataSectionMobile data={dataLiq} /> : <DataSectionDesktop data={dataLiq} />
}

export default DataSection
