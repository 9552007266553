import React from 'react'
import { Heading, Text, Flex } from '@hunnyswap/uikit'
import styled from 'styled-components'
import router from 'next/router'
import PageSection from 'components/PageSection'
import { PageMeta } from 'components/Layout/Page'

import CakeDataRow from './components/DataSection/CakeDataRow'
import BrandSection from './components/IntroSection/BrandSection'
import ProjectInfoSection from './components/TokenNomicSection/ProjectInfoSection'
import StakeToEarn from './components/TokenNomicSection/StakeToEarn'
import { StyledHomeButton, StyledSectionContent, StyledWrapSection } from './components/styles'
import BottomSection from './components/BottomSection'
import HunnyRace from './components/RaceSection/HunnyRace'
import IntroSection from './components/IntroSection'
import DataSection from './components/DataSection'
import TokenNomicSection from './components/TokenNomicSection'
import RaceSection from './components/RaceSection'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  margin-top: -85px;
`
const StyledHeroBackground = styled.img`
  min-width: 200%;
  margin-left: -50%;
  margin-top: 15%;
`

const StyledWrapImgHunnyGirl = styled.div`
  width: 25%;
`
const StyledHeroBannerText = styled.div`
  left: 15%;
  top: 150px;

  width: 55%;
  z-index: 1;
`
const StyledHeading = styled(Heading)`
  font-family: 'Spin Cycle OT' !important;
  font-weight: normal !important;
  text-transform: uppercase !important;
  font-size: min(4.3vw, 62px);
`

const StyledText = styled(Text)`
  font-family: 'Open Sans' !important;
`

const StyledWrapButton = styled.div`
  display: flex;
  width: 330px;
`

const StyledBottomBackground = styled.img`
  width: 50%;
  margin: auto;
`

const StyledSnowLine = styled.img`
  position: absolute;
  z-index: 1;
  bottom: 50%;
  width: 100%;
  transform: translate(-0%, 50%);

  max-height: 131px;
`

const Home: React.FC = () => {
  return (
    <StyledWrapper>
      <PageMeta />
      <IntroSection />
      <DataSection />
      <TokenNomicSection />
      <StyledWrapSection
        style={{
          margin: `0 auto 0`,
          padding: 0,
          backgroundImage: "url('/images/home/bottom-bg.jpg')",
          display: 'flex',
          flexDirection: 'column',
          backgroundPosition: 'bottom',
        }}
      >
        <BottomSection />
        <StyledBottomBackground src="/images/home/stake/fantasies.png" />
      </StyledWrapSection>
    </StyledWrapper>
  )
}

export default Home
