import React from 'react'
import styled from 'styled-components'
import { BoxProps, Box, FlexProps } from '@hunnyswap/uikit'
import Container from 'components/Layout/Container'
import CurvedDivider from './CurvedDivider'
import { ClipFill, DividerFill } from './types'

interface PageSectionProps extends BackgroundColorProps {
  svgFill?: string
  dividerComponent?: React.ReactNode
  hasCurvedDivider?: boolean
  dividerPosition?: 'top' | 'bottom'
  concaveDivider?: boolean
  containerProps?: BoxProps
  innerProps?: BoxProps
  clipFill?: ClipFill
  dividerFill?: DividerFill
  highLight?: boolean
}

interface BackgroundColorProps extends FlexProps {
  index: number
  background?: string
  getPadding?: () => string
}

const ChildrenWrapper = styled(Container)`
  min-height: auto;
  padding-top: 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 32px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 48px;
  }
`
const StyledHighLightCard = styled.div`
  position: absolute;
  width: 50%;
  max-width: 800px;
  height: 98%;
  bottom: 2px;
  background: rgba(193, 185, 222, 0.1);

  left: 50%;
  transform: translateX(-50%);
`

const PageSection: React.FC<PageSectionProps> = ({
  highLight,
  children,
  background,
  svgFill,
  index = 1,
  dividerComponent,
  dividerPosition = 'bottom',
  hasCurvedDivider = true,
  concaveDivider = false,
  clipFill,
  dividerFill,
  containerProps,
  innerProps,
}) => {
  return (
    <Box {...containerProps} position="relative">
      {highLight && <StyledHighLightCard />}

      {hasCurvedDivider && dividerPosition === 'top' && (
        <CurvedDivider
          svgFill={svgFill}
          index={index}
          concave={concaveDivider}
          dividerPosition={dividerPosition}
          dividerComponent={dividerComponent}
          clipFill={clipFill}
          dividerFill={dividerFill}
        />
      )}
      <ChildrenWrapper className="children-page-section" {...innerProps}>
        {children}
      </ChildrenWrapper>
      {hasCurvedDivider && dividerPosition === 'bottom' && (
        <CurvedDivider
          svgFill={svgFill}
          index={index}
          concave={concaveDivider}
          dividerPosition={dividerPosition}
          dividerComponent={dividerComponent}
          clipFill={clipFill}
          dividerFill={dividerFill}
        />
      )}
    </Box>
  )
}

export default PageSection
