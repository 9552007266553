import React from 'react'
import styled from 'styled-components'
import { sendEvent } from 'utils/google-analytics/actionSendEvent'
import { GOOGLE_ANALYTIC_EVENTS } from 'utils/google-analytics/event'
import { StyledHomeButton } from './styles'

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  top: 0px;

  width: 100%;
  margin: 0px auto;
  padding-top: 15%;
  padding-bottom: 30%;

  @media (max-width: 1400px) {
    max-width: 100%;
  }
`

const StyledTitle = styled.div`
  font-family: 'Spin Cycle OT';
  text-transform: uppercase;

  font-size: min(3.4vw, 42px);
  color: #fff;

  text-align: center;
`

const StyledWrapSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

const BottomSection = () => {
  return (
    <StyledWrapper>
      <StyledWrapSection>
        <StyledTitle>Open up your mind and let&apos;s illuminate your life</StyledTitle>
        <StyledHomeButton
          style={{ marginTop: 32 }}
          onClick={() => {
            sendEvent(GOOGLE_ANALYTIC_EVENTS.JOIN_TELE);

            window.open('https://t.me/HunnyFinance')
          }}
        >
          Join Our Community
        </StyledHomeButton>
      </StyledWrapSection>

      <StyledWrapSection style={{ marginBottom: 0 }} />
    </StyledWrapper>
  )
}

export default BottomSection
