import request, { gql } from 'graphql-request'
import { GRAPH_API_EXCHANGE } from 'config/constants/endpoints'

export const dayDataFieldsQuery = gql`
  fragment dayDataFields on DayData {
    id
    date
    volumeETH
    volumeUSD
    untrackedVolume
    liquidityETH
    liquidityUSD
    txCount
  }
`

export const dayDatasQuery = gql`
  query dayDatasQuery($first: Int! = 1000, $date: Int! = 0) {
    dayDatas(first: $first, orderBy: date, orderDirection: desc) {
      ...dayDataFields
    }
  }
  ${dayDataFieldsQuery}
`

export const getLiquidity = async (): Promise<Record<string, any>[]> => {
  try {
    const response = await request(
      GRAPH_API_EXCHANGE,
      gql`
        ${dayDatasQuery}
      `,
    )
    return response?.dayDatas || []
  } catch (err) {
    return []
  }
}
