import { useMatchBreakpoints } from '@hunnyswap/uikit'
import React from 'react'
import IntroSectionDesktop from './IntroSectionDesktop'
import IntroSectionMobile from './IntroSectionMobile'

const IntroSection: React.FC = () => {
  const { isMobile } = useMatchBreakpoints()

  return isMobile ? <IntroSectionMobile /> : <IntroSectionDesktop />
}

export default IntroSection
