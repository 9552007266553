import { Flex, Heading, Skeleton, Text } from '@hunnyswap/uikit'
import { useTranslation } from 'contexts/Localization'
import React from 'react'
import styled from 'styled-components'
import { StyledTwinkle } from '../styles'

const StyledColumn = styled(Flex)`
  justify-content: center;
  text-align: center;

  flex-direction: column;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 16px 8px;
  grid-template-columns: repeat(3, auto);
`

const StyledWrapper = styled.div`
  padding: 24px 16px;
  width: 100%;

  background: linear-gradient(90deg, rgba(0, 197, 139, 0.8) 0%, #222fa3 48.96%, rgba(0, 194, 220, 0.8) 100%);
  border: 2px solid #ffffff;
  box-shadow: 0px 16px 0px rgba(10, 0, 72, 0.5);
  border-radius: 16px;

  z-index: 1;
  position: relative;

  .skeleton {
    background: rgb(64, 216, 255);
    margin: 4px auto;
    height: 24px;
    width: 126px;
  }
`

const StyledImgFire = styled.img`
  position: absolute;
  top: 50%;
  max-height: 260px;
  max-width: 260px;

  width: auto;

  &.left {
    left: 0;
    transform: translateY(-60%) translateX(-60%);
  }
  &.right {
    right: 0;
    transform: translateY(-60%) translateX(40%);
  }

  @media (max-width: 1000px) {
    max-height: 200px;
    max-width: 200px;

    &.left {
      transform: translateY(-60%) translateX(-65%);
    }
    &.right {
      transform: translateY(-60%) translateX(45%);
    }
  }
`

const StyledTitle = styled(Text)`
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  font-family: sans-serif;
  text-align: center;
  font-family: 'Open Sans';

  @media (max-width: 380px) {
    font-size: 10px;
  }
`
const StyledHeading = styled(Heading)`
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  font-family: sans-serif;
  text-align: center;
  font-family: 'Open Sans';

  @media (max-width: 380px) {
    font-size: 14px;
  }
`

const emissionsPerSec = 0.1

const imagePath = '/images/'

const CakeDataRowMobile = ({ tvlString, mcapString }) => {
  const { t } = useTranslation()

  return (
    <StyledWrapper>
      <Grid>
        <StyledColumn flexDirection="column" justifyContent="center">
          {tvlString ? (
            <StyledHeading fontFamily="Open Sans" scale="lg">
              {t('$%tvlString%', { tvlString })}
            </StyledHeading>
          ) : (
            <>
              <Skeleton className="skeleton" />
            </>
          )}
          <StyledTitle color="textSubtle">{t('Liquidity')}</StyledTitle>
        </StyledColumn>

        <StyledColumn>
          {mcapString ? (
            <StyledHeading fontFamily="Open Sans" scale="lg">
              {t('$%marketCap%', { marketCap: mcapString })}
            </StyledHeading>
          ) : (
            <Skeleton className="skeleton" />
          )}
          <StyledTitle color="textSubtle">{t('Market cap')}</StyledTitle>
        </StyledColumn>
        <StyledColumn>
          <StyledHeading fontFamily="Open Sans" scale="lg">
            {t('%cakeEmissions%/second', { cakeEmissions: emissionsPerSec })}
          </StyledHeading>
          <StyledTitle color="textSubtle">{t('Circulating XOXO')}</StyledTitle>
        </StyledColumn>
      </Grid>
    </StyledWrapper>
  )
}

export default CakeDataRowMobile
