import { Flex, Heading, Skeleton, Text } from '@hunnyswap/uikit'
import { useTranslation } from 'contexts/Localization'
import React from 'react'
import styled from 'styled-components'
import { StyledTwinkle } from '../styles'

const StyledColumn = styled(Flex)`
  justify-content: center;
  text-align: center;

  flex-direction: column;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 16px 8px;
  grid-template-columns: repeat(3, auto);
  padding-left: 20px;
`

const StyledWrapperBorder = styled.div`
  box-sizing: border-box;
  border-radius: 31px;
  background: linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet, red);

  width: 70%;
  margin: auto;
  padding: 2px;
`

const StyledWrapper = styled.div`
  border-radius: 31px;
  padding: 36px 46px;
  width: 100%;

  background: linear-gradient(90deg, #01ddae 0%, #4241ee 50%, #16d2ec 100%);

  z-index: 1;
  position: relative;

  .skeleton {
    background: rgb(64, 216, 255);
    margin: 4px auto;
    height: 24px;
    width: 126px;
  }

  @media (max-width: 750px) {
    padding: 36px 16px;
  }
`

const StyledImgFire = styled.img`
  position: absolute;
  top: 50%;
  max-height: 260px;
  max-width: 260px;

  width: auto;

  &.left {
    left: 0;
    transform: translateY(-60%) translateX(-60%);
  }
  &.right {
    right: 0;
    transform: translateY(-60%) translateX(40%);
  }

  @media (max-width: 1000px) {
    max-height: 200px;
    max-width: 200px;

    &.left {
      transform: translateY(-60%) translateX(-65%);
    }
    &.right {
      transform: translateY(-60%) translateX(45%);
    }
  }
`

const StyledTitle = styled(Text)`
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  font-family: sans-serif;
  text-align: center;
  font-family: 'Open Sans';

  @media (max-width: 750px) {
    font-size: 12px;
  }
`

const emissionsPerSec = 0.1

const imagePath = '/images/'

const CakeDataRowDesktop = ({ tvlString, mcapString }) => {
  const { t } = useTranslation()

  return (
    <StyledWrapperBorder>
      <StyledWrapper>
        <StyledImgFire src={`${imagePath}badgeLeft.png`} alt="fire" className="left" />
        <Grid>
          <StyledColumn flexDirection="column" justifyContent="center">
            {tvlString ? (
              <Heading fontFamily="Open Sans" scale="lg">
                {t('$%tvlString%', { tvlString })}
              </Heading>
            ) : (
              <>
                <Skeleton className="skeleton" />
              </>
            )}
            <StyledTitle color="textSubtle">{t('Liquidity')}</StyledTitle>
          </StyledColumn>

          <StyledColumn>
            {mcapString ? (
              <Heading fontFamily="Open Sans" scale="lg">
                {t('$%marketCap%', { marketCap: mcapString })}
              </Heading>
            ) : (
              <Skeleton className="skeleton" />
            )}
            <StyledTitle color="textSubtle">{t('Market cap')}</StyledTitle>
          </StyledColumn>
          <StyledColumn>
            <Heading fontFamily="Open Sans" scale="lg">
              {t('%cakeEmissions%/second', { cakeEmissions: emissionsPerSec })}
            </Heading>
            <StyledTitle color="textSubtle">{t('Circulating XOXO')}</StyledTitle>
          </StyledColumn>
        </Grid>
        <StyledImgFire src={`${imagePath}badgeRight.png`} alt="fire" className="right" />

        <StyledTwinkle
          w="250px"
          time={1.5}
          src="/images/twinkle-1.png"
          className="center-left only-large-desktop"
          style={{ left: '-12%' }}
        />

        <StyledTwinkle
          w="200px"
          time={1.5}
          src="/images/twinkle.png"
          className="center-right only-large-desktop"
          style={{ right: '-10%' }}
        />
      </StyledWrapper>
    </StyledWrapperBorder>
  )
}

export default CakeDataRowDesktop
